import styled from 'styled-components';
import { device } from '../../utils/breakpoints.js';

export const MoreArticlesContainer = styled.div`
	grid-column: span 6;
	margin-top: 30px;


	@media ${device.desktop} {
		margin-top: 0px;
	}
	.button {
		margin-bottom: 24px;
		margin-top:5px;
		@media ${device.desktop} {
			margin-bottom: 65px;
		}
	}

`;

export const EditorialList = styled.div`
	display: flex;
	flex-direction: column-reverse;

	>div.teaser:first-of-type {
		>div {
			border-bottom: 1px solid transparent;
		}

	}
`;

export const TitleContainer = styled.div`
	margin: 0 auto;
	width: ${(props) => `calc(100% - 2 *${props.theme.mobileGutter})`};
	max-width: ${(props) => props.theme.mainMaxWidth};
	padding-top: 40px;

	border-top: 1px solid #d7e1e2;

	@media ${device.tablet} {
		width: ${(props) => `calc(100% - 2 *${props.theme.desktopGutter})`};
	}

	@media ${device.desktop} {
		padding-top: 70px;
	}
`;
