import React from "react"
import { graphql } from "gatsby"

import Editorial from "../components/Editorial/index"
import Layout from "../components/layout"
import SEO from "../components/seo"

const EditorialTemplate = props => {
  const post = props.data.wpgraphql.post
  const {
    title,
    content,
    featuredImage,
    colourFeatureImage,
    categories,
    date,
    slug,
    postLabel,
    author,
  } = post
  console.log("post:", post)

  return (
    <Layout article headerImage={featuredImage}>
      <SEO
        title={title}
        image={featuredImage}
        // description={excerpt}
        url={`${process.env.GATSBY_WEBSITE_DOMAIN}/editorial/${slug}/`}
      />
      <Editorial
        postLabel={postLabel}
        article
        color={colourFeatureImage}
        title={title}
        content={content}
        headerImage={featuredImage}
        categories={categories.nodes}
        date={date}
        shareLink={`${process.env.GATSBY_WEBSITE_DOMAIN}/editorial/${slug}/`}
        author={author}
      />
    </Layout>
  )
}

export default EditorialTemplate

export const pageQuery = graphql`
  query GET_POST($id: ID!) {
    wpgraphql {
      post(id: $id) {
        title
        author {
          name
          avatar {
            url
          }
        }
        colourFeatureImage
        content
        postLabel {
          postLabel
        }
        date
        slug
        link
        categories {
          nodes {
            name
            slug
          }
        }
        featuredImage {
          sourceUrl
          srcSet
          mediaDetails {
            width
            height
          }
        }
      }
    }
  }
`
