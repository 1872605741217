import React from 'react';
import { EditorialList, MoreArticlesContainer, TitleContainer } from './style.js';
import { Title, Secondary, GridContainer } from '../TeaserGrids/style.js';
import Button from '../Button/Button.js';

const MoreArticles = ({ children }) => {
	return (
		<MoreArticlesContainer>
			<TitleContainer>
				<Title inline>More</Title>
				<Secondary inline>articles</Secondary>
			</TitleContainer>

			<EditorialList>{children}</EditorialList>
			<GridContainer>
				<Button full path="/editorial" label="Discover more" />
			</GridContainer>
		</MoreArticlesContainer>
	);
};

export default MoreArticles;
