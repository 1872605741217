import React from "react"
import gql from "graphql-tag"
import { useQuery } from "@apollo/react-hooks"
import PostTemplate from "../../templates/post"
import { postData } from "../../fragments/data"

const GET_POSTS = gql`
  query($postID: Int!) {
    postBy(postId: $postID) {
      ${postData}
    }
  }
`

const PreviewPost = ({ id }) => {
  const { loading, error, data } = useQuery(GET_POSTS, {
    variables: { postID: id },
  })

  return (
    <>
      {loading && <p>Loading ...</p>}

      {data && <PostTemplate pageContext={data.postBy} />}

      {error && error.networkError && (
        <>
          <h3>Expired authToken</h3>
          <p>Click the preview button to view this page</p>
        </>
      )
      /* <pre>{JSON.stringify(error.networkError, null, 2)}</pre> */
      }
    </>
  )
}

export default PreviewPost
