import styled from "styled-components"
import { device } from "../../utils/breakpoints"

export const Container = styled.div`
  margin: 18px 0;

  @media ${device.tablet} {
    margin-top: 0;
  }

  @media ${device.large} {
    max-width: 610px;
  }

  h1,
  h2,
  h3,
  h4,
  p,
  ul,
  ol {
    padding-right: 20px;

    @media ${device.tablet} {
      padding-right: 40px;
    }

    @media ${device.large} {
      padding-right: 0;
    }
  }
`
export const Title = styled.h1`
  font-weight: 700;
  font-size: 36px;
  color: ${props => props.theme.colors.black};
  letter-spacing: -0.5px;
  line-height: 40px;
  margin-top: 8px;

  @media ${device.large} {
    font-size: 48px;
    color: #19191a;
    letter-spacing: -1px;
    line-height: 48px;
    margin-top: 16px;
  }
`
export const TagsStyle = styled.div`
  a {
    font-weight: 700;
    font-size: 10px;
    color: ${props => props.theme.colors.teal};
    letter-spacing: 0.42px;
    line-height: 100%;
    margin-right: 16px;
    text-decoration: none;
    text-transform: uppercase;

    @media ${device.large} {
      font-size: 12px;
      letter-spacing: 0.5px;
      line-height: 24px;
    }

    :hover {
      opacity: 0.7;
      transition: 70ms;
    }
  }
`
export const Content = styled.div`
  .wp-block-button {
    display: inline-block;
    background: ${props => props.theme.colors.pink};
    transition: 70ms;
    color: white;
    font-weight: 600;
    padding: 12px 20px;
    outline: none;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin: 2px 0;

    a {
      color: white !important;
      font-size: 16px;
      background-image:none;

      @media ${device.desktop} {
        font-size: 18px;
      }
      ::after {
        display: none;
      }
    }

    :hover {
      background: #c8295f;
    }
  }
  hr {
    background: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 1px;
    margin-bottom: 25px;
    margin-top: 25px;
  }

  figcaption {
    font-size: 14px;
    @media ${device.desktop} {
      font-size: 16px;
    }
  }

  figure.wp-block-embed {
    .entry-content-asset {
      /padding-top: 56.25%;
      /* position: relative; */

      > iframe {
        max-width: 800px !important;
        max-height: 1360px;
        margin: 0;
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
      }
    }

    &.is-type-video {
      .entry-content-asset {
        padding-top: 56.25%;
        position: relative;

        > iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  figure.wp-block-image,
  figure.wp-block-video,
  figure.wp-block-embed {
    margin: 30px 0;

    @media ${device.desktop} {
      margin: 40px 0;
    }

    @media ${device.large} {
      margin: 60px 0;
    }

    &.alignwide {
      @media ${device.desktop} {
        width: calc((100vw - 120px - 10px) * (2 / 3));

        margin-left: -106px;
      }

      @media ${device.large} {
        width: calc((1200px - 25px) * (2 / 3));

        margin-left: -106px;
      }
    }

    img {
      display: block;
    }

    video {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  cite {
    font-size: 14px;
    line-height: 150%;
    font-weight: bold;
    font-style: normal;
    color: black;
    display: inline-block;
    padding-left: 18px;
    position: relative;
    margin-top: 17px;
    @media ${device.large} {
      margin-top: 25px;
    }
    ::before {
      content: "";
      position: absolute;
      left: 0;
      top: 9.5px;
      height: 2px;
      width: 10px;
      background: black;
    }
  }

  blockquote {
    /* font-style: italic; */
    font-weight: 600;
    font-size: 22px;
    color: ${props => props.theme.colors.teal};
    letter-spacing: 0;
    line-height: 32px;
    margin: 25px 0;

    @media ${device.large} {
      font-size: 28px;
      line-height: 42px;
      margin: 40px 0;
    }

    p {
      /* font-style: italic; */
      font-weight: 600;
      font-size: 22px;
      color: ${props => props.theme.colors.teal};
      letter-spacing: 0;
      line-height: 32px;
      margin: 0;

      @media ${device.large} {
        font-size: 28px;
        line-height: 42px;
      }
    }
  }

  ul, ol {
    margin-left: 18px;
    li {
      font-size: 16px;
      list-style-type: none;
      position: relative;
      @media ${device.large} {
        font-size: 20px;
      }
    }
  }

  ul {
    li {
      ::before {
        content: "";
        height: 6px;
        width: 6px;
        background: #24a49e;
        display: block;
        border-radius: 50%;
        position: absolute;
        top: 8px;
        left: -17px;
  
        @media ${device.large} {
          top: 12px;
          font-size: 20px;
        }
      }
    }
  }

  ol {
    list-style: none;
    counter-reset: item;

    li {
      position:relative;
      counter-increment: item;
      
      ::before {
        content:"";
        position:absolute;
        top:0px;
        left:-18px;
        content: counter(item);
        border-radius: 100%;
        color: #24a49e;
        font-weight:bold;
        text-align: center;
        display: inline-block;

        @media ${device.large} {
          left:-20px;
          top:1px;
        }
      }
    }
  }
 
  p {
    font-size: 16px;
    color: ${props => props.theme.colors.grey[3]};
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 16px;
    margin-bottom: 25px;

    a {
      display: inline-block;
    }

    @media ${device.large} {
      font-size: 20px;
      letter-spacing: 0;
      line-height: 30px;
      margin-top: 27px;
      margin-bottom: 27px;
    }
  }

  p.small-text {
    font-size: 16px !important;
    line-height: 24px !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  h2 + p,
  h3 + p,
  h4 + p {
    margin-top: 0;
  }

  a {
    color: ${props => props.theme.colors.black};
    font-weight: 700;
    text-decoration-line: none;
    padding-bottom:2px;
    background-image: linear-gradient(to right, #00CDC2, #00CDC2);
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: 100% 2px;
    transition: "120ms";
    position: relative;
    :hover {
      color: ${props => props.theme.colors.teal};
      transition: 90ms;
    }

    // ::after {
    //   content: "";
    //   position: absolute;
    //   left: 0;
    //   bottom: 0px;
    //   display: inline-block;
    //   height: 2px;
    //   width: 100%;
    //   background: ${props => props.theme.colors.turquoise};
    // }
  }

  .wp-block-image > a::after {
    background: none;
  }

  h2,
  h3,
  h4,
  h5 {
    margin: 25px 0;

    a {
      ::after {
        display: none;
      }
    }
  }

  h2 {
    font-size: 30px;
    @media ${device.desktop} {
      font-size: 36px;
    }
  }

  h3 {
    font-size: 26px;

    @media ${device.desktop} {
      font-size: 32px;
    }
  }

  h4 {
    font-size: 22px;

    @media ${device.desktop} {
      font-size: 28px;
    }
  }

  h5 {
    font-size: 22px;

    @media ${device.desktop} {
      font-size: 24px;
    }
  }



  figure.wp-block-table {
    overflow: scroll;
    width: auto;
    max-width:100vw;
    margin: 0 -20px;
    padding: 0 20px;
    
    table { 
      border-spacing: 0;
      border-collapse: separate;
      table-layout: fixed;
    }

  
    // reset top row curve if no header
    table:has(thead) { 
       tbody {
        tr:first-child {
          td {
            border-top:none;
          }
          td:first-child {
            border-top-left-radius:0;
          }     
             
          td:last-child {
            border-top-right-radius:0;

          }         
        }
      }
    }

    // header
    thead {
      tr {
        color:white;
        th:first-child {
          background:#005358 !important;
          border-top-left-radius:12px;
        }

        th:nth-child(2n+1) {
          background:#04848C;
          
        }
        th:nth-child(2n+2) {
          background:#00737A;
        }

         th:last-child {
          border-top-right-radius:12px;
        }
      }
    }

    // general
    th, td {
      vertical-align: baseline;
      line-height: 125%;
      padding:16px 12px;
      width:132px;
        @media ${device.tablet} {
          width:auto;
        }

      &:first-child {
        @media ${device.tablet} {
          width:26%;
        }
      }
    }

    tbody {
      tr {
        border:none;
      }

      td {
        border:none;
        border-right:1px solid #EAEAEA; 
      }
      

      // cell backgrounds
      tr:nth-child(2n+1) {
        td:first-child {
          background:#F7F7F7;
          font-weight:600;
          border-left:1px solid #EAEAEA; 
        }        
      }

      tr:nth-child(2n+2) {
        td:first-child {
          background:#EBEDEE;
          font-weight:600;
          border-left:1px solid #EAEAEA; 
        }

        td {
          background:#F7F7F7;
        }
      }
        
     
      // first row corners

      tr:first-child {
        td {
          border-top:1px solid #EAEAEA; 
        }
        td:first-child {
          border-top-left-radius:12px;
          border-left:1px solid #EAEAEA; 
        }     
            
        td:last-child {
          border-top-right-radius:12px;
          border-right:1px solid #EAEAEA; 
        }         
      }

      // last row corners
      tr:last-child {
        td {
          border-bottom:1px solid #EAEAEA; 
        }
        td:first-child {
          border-bottom-left-radius:12px;
          border-left:1px solid #EAEAEA; 
        }     
             
        td:last-child {
          border-bottom-right-radius:12px;
          border-right:1px solid #EAEAEA; 
        }         
      }
    }
  }
`

export const Published = styled.h6`
  font-weight: 600;
  font-size: 12px;
  color: ${props => props.theme.colors.grey[2]};
  letter-spacing: 0;
  line-height: 12px;

  @media ${device.tablet} {
    font-size: 13px;
    line-height: 30px;
    margin: 0;
    vertical-align: center;
  }
  @media ${device.desktop} {
    margin-bottom: 40px;
  }
`
