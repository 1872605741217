import React from "react"
import gql from "graphql-tag"
import { useQuery } from "@apollo/react-hooks"
import EditorialTemplate from "../../templates/editorial"

const GET_EDITORIALS = gql`
  query($editorialID: Int!) {
    editorialBy(editorialId: $editorialID) {
      title
      author {
        name
        avatar {
          url
        }
      }
      colourFeatureImage
      postLabel {
        postLabel
      }
      content
      date
      slug
      link
      categories {
        nodes {
          name
          slug
        }
      }
      featuredImage {
        sourceUrl
        srcSet
        mediaDetails {
          width
          height
        }
      }
    }
  }
`

const PreviewEditorial = ({ id }) => {
  const { loading, error, data } = useQuery(GET_EDITORIALS, {
    variables: { editorialID: id },
  })

  return (
    <>
      {loading && <p>Loading ...</p>}

      {data && (
        <EditorialTemplate data={{ wpgraphql: { post: data.editorialBy } }} />
      )}

      {error && error.networkError && (
        <>
          <h3>Expired authToken</h3>
          <p>Click the preview button to view this page</p>
        </>
      )
      /* <pre>{JSON.stringify(error.networkError, null, 2)}</pre> */
      }
    </>
  )
}

export default PreviewEditorial
