const categoryTeaserData = `
  title
  link
  id
  postType:__typename
  colourFeatureImage
  slug
  categories {
    nodes {
      name
      slug
      link
    }
  }
  excerpt
  featuredImage {
    sourceUrl
    srcSet
    mediaDetails {
      height
      width
    }
  }
`

const postData = `
  postId
  title
  author {
    name
    avatar {
      url
    }
  }
  colourFeatureImage
  excerpt
  metadata {
    lowresImages
  }
  date
  slug
  link
  categories {
    nodes {
      name
      slug
      link
    }
  }
  content
  postLabel {
    postLabel
  }
  featuredImage {
    sourceUrl
    srcSet
    mediaDetails {
      height
      width
    }
    caption
    altText
    description
  }
  videoAttributes {
    embedCode
    videoOrder
  }

  avBanner {
    avBannerNumber
  }

  
  attachments(first: 25) {
    nodes {
      sourceUrl
      srcSet
      mediaDetails {
        height
        width
      }
      caption
      altText
      description
    }
  }
`

module.exports = {
  categoryTeaserData,
  postData,
}
