import React, { useEffect, useRef } from "react"
import Article from "./article"
import Shares from "../Shares/index"
import { Products } from "../Products"
import { Teaser } from "../Teaser"
import { AdvertisementWrapper } from "../AdvertisementWrapper"
import { GoogleAd } from "../GoogleAd"
import { LatestPostsData } from "../../hooks/latestPosts"
import { ResponsiveGatsbyImage } from "../ResponsiveGatsbyImage"
import { LatestEditorialsData } from "../../hooks/latestEditorials"

import {
  // Images,
  Container,
  HeaderContainer,
  InnerContainer,
  ArticleImageWrapper,
  MainContainer,
  SecondaryContainer,
  HeaderBackground,
  // VideoWrapper,
  // ArticleImage
} from "./indexStyles"
import { ArticleSliderGrid } from "../TeaserGrids"
import { FollowUs } from "../FollowUs"
import MoreArticles from "../MoreArticles/MoreArticles"
import NewsletterPopup from "../NewsletterPopup/NewsletterPopup"

export default function Index(props) {
  const latestPostsHook = LatestPostsData()
  const latestPosts = latestPostsHook.posts.nodes.slice(0, 8)

  const latestEditorials = LatestEditorialsData()
  const otherEditorials = latestEditorials.posts.filter(
    editorial => editorial.title !== props.title
  )

  const rgb = props.color && JSON.parse(props.color).join()

  const articleContainer = useRef(null)

  useEffect(() => {
    function loadPinterestScript() {
      const script = document.createElement("script")
      script.src = "//assets.pinterest.com/js/pinit.js"
      script.type = "text/javascript"
      script.dataset.pinBuild = "pinterestBuild"
      script.async = true
      script.defer = true
      articleContainer.current.appendChild(script)
    }

    if (!window.pinterestBuild) {
      loadPinterestScript()
    } else {
      window.pinterestBuild()
    }
  }, [])

  return (
    <div>
      <Container ref={articleContainer}>
        <HeaderBackground noArticleHeaderImage={props.noArticleHeaderImage}>
          <ResponsiveGatsbyImage
            noAnimate
            loading="eager"
            noFade
            src={props.headerImage.sourceUrl}
            srcSet={props.headerImage.srcSet}
            sizes="(min-width: 1024px) 800px, 100vw"
            height={props.headerImage.mediaDetails.height}
            width={props.headerImage.mediaDetails.width}
            style={{ backgroundColor: props.color ? `rgb(${rgb})` : null }}
          />
        </HeaderBackground>

        <MainContainer>
          <ArticleImageWrapper>
            <ResponsiveGatsbyImage
              loading="eager"
              noFade
              src={props.headerImage.sourceUrl}
              srcSet={props.headerImage.srcSet}
              sizes="(min-width: 1024px) 800px, 100vw"
              height={props.headerImage.mediaDetails.height}
              style={{ backgroundColor: props.color ? `rgb(${rgb})` : null }}
              width={props.headerImage.mediaDetails.width}
            />
          </ArticleImageWrapper>
          <HeaderContainer>
            <Shares link={props.shareLink} />
            <InnerContainer>
              <Article
                postLabel={props.postLabel}
                article={props.article}
                date={props.date}
                title={props.title}
                content={props.content}
                categories={
                  props.categories.length
                    ? props.categories
                    : [{ slug: "editorial", name: "Editorial" }]
                }
              />
            </InnerContainer>
          </HeaderContainer>

          <AdvertisementWrapper articleHorizontal>
            <GoogleAd
              className="articleHorizontal"
              client="ca-pub-4461681428787239"
              slots={["3835518671", "3835518671", "7179673797"]}
            />
          </AdvertisementWrapper>
          <NewsletterPopup />

          <Shares link={props.shareLink} author={props.author} />
        </MainContainer>

        <SecondaryContainer>
          <AdvertisementWrapper articleSquare>
            <GoogleAd
              className="square"
              client="ca-pub-4461681428787239"
              slots={["5453876193"]}
            />
          </AdvertisementWrapper>

          <AdvertisementWrapper articleVertical>
            <GoogleAd
              className="articleVertical"
              client="ca-pub-4461681428787239"
              slots={["8983415873"]}
            />
          </AdvertisementWrapper>

          <ArticleSliderGrid title="More articles" editorialPost>
            {otherEditorials.slice(0, 4).map((teaser, index) => {
              return (
                <Teaser
                  little
                  title={teaser.title}
                  link={teaser.link}
                  color={teaser.colourFeatureImage}
                  key={index}
                  excerpt={teaser.excerpt}
                  img={teaser.featuredImage}
                  categories={teaser.categories.nodes}
                />
              )
            })}
          </ArticleSliderGrid>

          <Products
            marginTop={props.article ? null : true}
            article
            title="#want"
          />
          <FollowUs />
          <AdvertisementWrapper articleSquare>
            <GoogleAd
              className="square"
              client="ca-pub-4461681428787239"
              slots={["8589151908"]}
            />
          </AdvertisementWrapper>

          <ArticleSliderGrid title="More inspiration">
            {latestPosts.slice(0, 4).map((teaser, index) => {
              return (
                <Teaser
                  title={teaser.title}
                  link={teaser.link}
                  color={teaser.colourFeatureImage}
                  key={index}
                  excerpt={teaser.excerpt}
                  img={teaser.featuredImage}
                  categories={teaser.categories.nodes}
                />
              )
            })}
          </ArticleSliderGrid>
        </SecondaryContainer>
      </Container>

      <MoreArticles>
        {otherEditorials
          .slice(0, 4)
          .reverse()
          .map((teaser, index) => {
            return (
              <Teaser
                key={index}
                title={teaser.title}
                editorial
                categories={teaser.categories.nodes}
                link={teaser.link}
              />
            )
          })}
      </MoreArticles>
    </div>
  )
}
