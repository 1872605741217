import React from "react"
import { Router } from "@reach/router"
import PreviewPost from "../components/Preview/PreviewPost"
import PreviewEditorial from "../components/Preview/PreviewEditorial"

const PreviewPage = () => {
  return (
    <>
      <div
        style={{
          background: "#E4316E",
          color: "#fff",
          top: 0,
          width: "100%",
          textAlign: "center",
          padding: "10px 0",
          position: "sticky",
          zIndex: 10,
          boxShadow: "1px 1px 18.7px rgba(0,0,0,0.5)",
          fontWeight: 700,
        }}
      >
        Preview Page
      </div>
      <Router>
        <PreviewPost path="/preview/post/:id/:token" />
        <PreviewEditorial path="/preview/editorial/:id/:token" />

      </Router>
    </>
  )
}

export default PreviewPage
